
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiAccount, ApiBrands, ApiBase } from "@/core/api";
import SetConfigModal from "@/views/influencer-platform/influencer-accounts/SetConfigModal.vue";
import moment from "moment";
import _ from "lodash";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { formatDate, setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "account-profile",
  components: {
    SetConfigModal,
  },
  setup() {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      account_id: route.params.id,
      profile: {
        rfm_group_trend: "",
        rfm_group_last_month: "",
        recency_last_order: "",
        frequency_orders_last_thirty_days: "",
        monetary_gtv_last_thirty_days: "",
        activity_short_term: "",
        activity_mid_term: "",
        activity_long_term: "",
        activity_last_login: "",
        activity_retention: "",
        dependence_pim: "",
        dependence_order: "",
        dependence_fulfillment: "",
        dependence_application: "",
        social_fission_consumer: "",
        social_fission_cycle_real_time: "",
        focused_brand_level: [],
        focused_brands: [],
        ordered_brands: [],
        inquired_brands: "",
        id: "",
      },
      extend: {
        brand_order_gtv: "",
        created_at: "",
        created_uid: "",
        deleted_at: "",
        deleted_uid: "",
        first_login_time: "",
        registration_date: "",
        id: "",
        inquire_times_history: "",
        inquire_times_last_thirty_days: "",
        last_login_time: "",
        last_order_date: "",
        login_times_last_fifteen_days: "",
        order_times_last_thirty_days: "",
        register_date: "",
        te_director: "",
        total_order_amount_gmv: "",
        total_order_amount_gtv: "",
        total_orders_gmv: "",
        total_orders_gtv: "",
        updated_at: "",
        updated_uid: "",
      },
    });
    const focusedBrandsOptions = ref([]);
    const focusedBrandsLoading = ref<boolean>(false);
    const orderedBrandsOptions = ref([]);
    const orderedBrandsLoading = ref<boolean>(false);
    const inquiredBrandsOptions = ref([]);
    const inquiredBrandsLoading = ref<boolean>(false);
    const focusedBrandsLevelOptions = ref([]);

    const getFocusedBrandsLevelOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["global_brand_level"],
      });
      if (data.code == 0) {
        focusedBrandsLevelOptions.value = data.data.global_brand_level.items;
      }
    };

    const getBrandsList = (type: number, search?: string, id?: number) => {
      if (type == 0) {
        focusedBrandsLoading.value = true;
      } else if (type == 1) {
        orderedBrandsLoading.value = true;
      } else {
        inquiredBrandsLoading.value = true;
      }
      let params =
        search != ""
          ? {
              search_value: search,
            }
          : {
              id: id,
            };
      ApiBrands.getBrandSourceData(params)
        .then(({ data }) => {
          if (type == 0) {
            focusedBrandsLoading.value = false;
          } else if (type == 1) {
            orderedBrandsLoading.value = false;
          } else {
            inquiredBrandsLoading.value = false;
          }
          if (data.code == 0) {
            if (type == 0) {
              focusedBrandsOptions.value = data.data;
            } else if (type == 1) {
              orderedBrandsOptions.value = data.data;
            } else {
              inquiredBrandsOptions.value = data.data;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const debounceBrandSearch = _.debounce(getBrandsList, 1000);
    const searchFocusedBrandsItems = (query) => {
      debounceBrandSearch(0, query);
    };
    const searchOrderedBrandsItems = (query) => {
      debounceBrandSearch(1, query);
    };
    const searchInquiredBrandsItems = (query) => {
      debounceBrandSearch(2, query);
    };

    const getAccountProfileData = async () => {
      loading.value = true;
      const { data } = await ApiAccount.getAccountProfileData({
        account_id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
        if (data.data.profile.focused_brands.length != 0) {
          getBrandsList(0, "", data.data.profile.focused_brands);
        }
        if (data.data.profile.ordered_brands.length != 0) {
          getBrandsList(1, "", data.data.profile.ordered_brands);
        }
        if (data.data.profile.inquired_brands != "") {
          getBrandsList(2, "", data.data.profile.inquired_brands);
        }
      }
    };

    const backToList = () => {
      if (route.path.indexOf("account-conversions") != -1) {
        router.push("/interactive-marketing/account-conversions");
      } else {
        router.push("/influencer-platform/influencer-accounts");
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }

          ApiAccount.updateAccountProfileData(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    onBeforeMount(() => {
      getAccountProfileData();
    });

    onMounted(() => {
      getFocusedBrandsLevelOptions();
      setModuleBCN(t, route, router);
    });

    return {
      t,
      formatDate,
      loading,
      formRef,
      submitButton,
      formData,
      focusedBrandsOptions,
      focusedBrandsLoading,
      orderedBrandsOptions,
      orderedBrandsLoading,
      inquiredBrandsOptions,
      inquiredBrandsLoading,
      focusedBrandsLevelOptions,
      getFocusedBrandsLevelOptions,
      searchFocusedBrandsItems,
      searchOrderedBrandsItems,
      searchInquiredBrandsItems,
      backToList,
      submit,
    };
  },
});
