
import { defineComponent, onBeforeMount, ref, onMounted } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { ApiAccount } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { commonChangeDefaultDate, commonDefaultDate } from "@/core/directive/function/common";

export default defineComponent({
  name: "add-config-modal",
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const setConfigModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const { t } = useI18n();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      deadline_date: commonDefaultDate(moment().format("YYYY-MM-DD")),
      last_order_interval: "60",
      frequency_day_base: "120",
      monetary_day_base: "720",
      frequency_spu_base: "2",
      monetary_gtv_base: "3000",
      social_fission_consumer: "200",
      social_fission_cycle: "150",
    });

    const DefaultUpdateData = ref({});

    const rules = ref({
      deadline_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      last_order_interval: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      frequency_day_base: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      monetary_day_base: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      social_fission_consumer: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      social_fission_cycle: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const getFormData = () => {
      loading.value = true;
      ApiAccount.getProfileConfigData({})
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            DefaultUpdateData.value = data.data.list;
            Object.keys(data.data.list).forEach((item) => {
              formData.value[item] = data.data.list[item];
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          Object.keys(DefaultUpdateData.value).forEach((item) => {
            DefaultUpdateData.value[item] = formData.value[item];
          });
          ApiAccount.updateProfileConfigData({ list: DefaultUpdateData.value })
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  // resetForm();
                  hideModal(setConfigModalRef.value);
                  runProfileUpdateCommand();
                });
              } else {
                resetForm();
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              resetForm();
              console.log(error);
            });
        } else {
          resetForm();
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const runProfileUpdateCommand = () => {
      loading.value = true;
      ApiAccount.runProfileCommand({
        deadline_date: formData.value.deadline_date,
      })
        .then(({ data }) => {
          loading.value = false;
          console.log("completed");
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onMounted(() => {
      modalHideListener(setConfigModalRef.value, () => {
        emit("reset-form");
      });
    });

    onBeforeMount(() => {
      getFormData();
    });

    return {
      commonChangeDefaultDate,
      loading,
      formData,
      rules,
      formRef,
      setConfigModalRef,
      submitButton,
      t,
      getFormData,
      submit,
    };
  },
});
